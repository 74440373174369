<template>
    <div>
        <Pane />
        <a-select style="width: 100px;margin-left: 10px;margin-top: 10px;" @change="getInfo()" v-model="year">
            <a-select-option v-for="item in yearList" :key="item.value" :value="item.value">
                {{ item.name }}
            </a-select-option>
        </a-select>
        <a-card class="container">
            <a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" :colon="false" :form="form">
                <div style="font-weight: bold;font-size: 16px;">年度数据(万元)</div>
                <a-row>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="净值估算系数">
                            <a-input type="number" v-model="detail.targetCoefficient" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row v-if="type==0">
                    <div style="margin-left: 33px;">
                        EPC
                    </div>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="净额">
                            <a-input type="number" v-model="detail.epcNetAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="已签订">
                            <a-input type="number" v-model="detail.epcArchiveAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="已承接">
                            <a-input type="number" v-model="detail.epcUndertakeAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="目标计算方式">
                            <a-select v-model="detail.typeEpcContract" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="年度目标">
                            <a-input type="number" v-model="detail.epcTargetAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row v-if="type==1">
                    <div style="margin-left: 33px;">
                        EPC
                    </div>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="已确认">
                            <a-input type="number" v-model="detail.projectEpcAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="目标计算方式">
                            <a-select v-model="detail.typeEpcProject" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="年度目标">
                            <a-input type="number" v-model="detail.projectEpcTargetAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row v-if="type==2">
                    <div style="margin-left: 33px;">
                        EPC
                    </div>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="已确认">
                            <a-input type="number" v-model="detail.paymentEpcAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="目标计算方式">
                            <a-select v-model="detail.typeEpcPayment" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="年度目标">
                            <a-input type="number" v-model="detail.paymentEpcTargetAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row v-for="item in detail.deptList">
                    <div style="margin-left: 33px;">
                        {{ item.deptName}}
                    </div>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==0">
                        <a-form-item label="净额">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.wisdomNetAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.netAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==0">
                        <a-form-item label="已签订">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.wisdomArchiveAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.archiveAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==0">
                        <a-form-item label="已承接">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.wisdomUndertakeAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.undertakeAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==1">
                        <a-form-item label="已确认">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.wisdomProjectAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.projectAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==2">
                        <a-form-item label="已回款">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.wisdomPaymentAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.paymentAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==0">
                        <a-form-item label="目标计算方式">
                            <a-select v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.target.typeWisdomContract" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                            <a-select v-else v-model="item.target.typeContract" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==1">
                        <a-form-item label="目标计算方式">
                            <a-select v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.target.typeWisdomProject" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                            <a-select v-else-if="item.deptName == '分院' " :disabled="true" v-model="item.target.typeProject" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                            <a-select v-else v-model="item.target.typeProject" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==2">
                        <a-form-item label="目标计算方式">
                            <a-select v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.target.typeWisdomPayment" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                            <a-select v-else-if="item.deptName == '分院' " :disabled="true" v-model="item.target.typePayment" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                            <a-select v-else v-model="item.target.typePayment" placeholder="请输入" style="width: 180px">
                                <a-select-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :value="item.value"
                                >{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==0">
                        <a-form-item label="年度目标">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.target.wisdomContractAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.target.contractAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==1">
                        <a-form-item label="年度目标">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.target.wisdomProjectAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.target.projectAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12" v-if="type==2">
                        <a-form-item label="年度目标">
                            <a-input type="number" v-if="item.deptName == '智慧产业院(智慧类)' " v-model="item.target.wisdomPaymentAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input type="number" v-else v-model="item.target.paymentAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <div v-if="type==0" style="font-weight: bold;font-size: 16px;">月度承接数据(万元)</div>
                <div v-if="type==1" style="font-weight: bold;font-size: 16px;">月度进度确认数据(万元)</div>
                <div v-if="type==2" style="font-weight: bold;font-size: 16px;">月度回款数据(万元)</div>
                <a-row v-for="item in detail.monthList">
                    <div style="margin-left: 33px;">
                        {{ item.month}}月
                    </div>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="总院设计">
                            <a-input v-if="type==0" type="number" v-model="item.prodUndertakeAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==1" type="number" v-model="item.projectProdAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==2" type="number" v-model="item.paymentProdAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="分院设计">
                            <a-input v-if="type==0" type="number" v-model="item.branchUndertakeAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==1" type="number" v-model="item.projectBranchAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==2" type="number" v-model="item.paymentBranchAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="EPC">
                            <a-input v-if="type==0" type="number" v-model="item.epcUndertakeAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==1" type="number" v-model="item.projectEpcAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==2" type="number" v-model="item.paymentEpcAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="智慧类">
                            <a-input v-if="type==0" type="number" v-model="item.wisdomUndertakeAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==1" type="number" v-model="item.projectWisdomAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-if="type==2" type="number" v-model="item.paymentWisdomAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <div class="center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button type="primary" :loading="loading" @click="handleSubmit">保存</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import request from "@/api/request";
import organization from "@/mixins/organization";
function save(data) {
    return request({
        url: "project-service/dsContract/update",
        method: "post",
        data
    });
}
function getDetail(year,type) {
  return request({
    url: "project-service/dsContract/totalAndDept/" + year + '/' + type,
  });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        typeList() {
            return this.findDataDict("bid_target_calc_method");
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            detail: {},
            yearList: [],
            year: '',
            type: ''
        };
    },
    mounted() {
        const { query } = this.$route;
        this.getYearList()
        const { type, year } = query || {};
        this.type =  type
        this.year = year
        this.getInfo()
        
    },
    methods: {
        getInfo() {
            getDetail(this.year, this.type == 0?'ori':'branchSum').then(res => {
                res.deptList.forEach((el,index) => {
                    if(el.deptName == '分院'){
                        res.deptList[index].target.typePayment = 'bid_target_calc_method_total'
                        res.deptList[index].target.typeProject = 'bid_target_calc_method_total'
                    }
                    console.log(this.detail.deptList)
                });
                this.detail = res;
                try {
                    this.detail.deptList.forEach((el,index) => {
                        if(el.deptId == '1221122277233463296'){
                            const obj = JSON.parse(JSON.stringify(el))
                            el.deptName = '智慧产业院(设计类)'
                            obj.deptName = '智慧产业院(智慧类)'
                            this.detail.deptList.splice(index + 1, 0, obj)
                            throw new Error();
                        }
                    });
                } catch(e) {
                   console.log(e)
                }
            });
        },
        getYearList() {
            const now = new Date().getFullYear()
            for (let index = 2021; index <= now; index++) {
                this.yearList.push(
                    {
                        name: index,
                        value: index
                    }
                )
            }
        },
        hanldeSave() {

        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.loading = true;
                    const { query } = this.$route;
                    const { id } = query || {};
                    let obj = {}
                    this.detail.deptList.forEach((el,index) => {
                        if(el.deptName == '智慧产业院(设计类)'){
                            obj = el
                        }
                        if(el.deptName == '智慧产业院(智慧类)'){
                            obj.wisdomNetAmount = el.wisdomNetAmount
                            obj.wisdomArchiveAmount = el.wisdomArchiveAmount
                            obj.wisdomUndertakeAmount = el.wisdomUndertakeAmount
                            obj.wisdomProjectAmount = el.wisdomProjectAmount
                            obj.wisdomPaymentAmount = el.wisdomPaymentAmount
                            obj.target.typeWisdomContract = el.target.typeWisdomContract
                            obj.target.typeWisdomProject = el.target.typeWisdomProject
                            obj.target.typeWisdomPayment = el.target.typeWisdomPayment
                            obj.target.wisdomContractAmount = el.target.wisdomContractAmount
                            obj.target.wisdomProjectAmount = el.target.wisdomProjectAmount
                            obj.target.wisdomPaymentAmount = el.target.wisdomPaymentAmount
                            this.detail.deptList.splice(index, 1)
                        }
                    })
                    console.log(this.detail.deptList)
                    save(this.detail)
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>